import { Box, Typography } from "@mui/material";
import "../assets/style/quote.css";
import { useContext } from "react";
import LocaleContext from "../contexts/LocaleContext";

function Quote() {
  const localeContext=useContext(LocaleContext)

  return (
    <Box className="quote">
    <Typography variant="h6">{localeContext.i18n.t("quote.title")}</Typography>
        <Typography variant="body1"><q>{localeContext.i18n.t("quote.description")}</q></Typography>
    </Box>
  );
}

export default Quote; 
