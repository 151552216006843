import feature0 from "../assets/images/feature0.png"
import feature1 from "../assets/images/feature1.png"
import feature2 from "../assets/images/feature2.png"
import feature3 from "../assets/images/feature3.png"

import Feature from "./Feature";
import "../assets/style/features.css";
import { useContext } from "react";
import LocaleContext from "../contexts/LocaleContext";
import { Box } from "@mui/material";


function Features() {
    const localeContext=useContext(LocaleContext)
    const features=[{
        imageURL:feature0,
        description:localeContext.i18n.t("features.0")
    },
    {
        imageURL:feature2,
        description:localeContext.i18n.t("features.2"),
        shortcut:" (alt + a)"
    },{
        imageURL:feature3,
        description:localeContext.i18n.t("features.3")
    },
    {
        imageURL:feature1,
        description:localeContext.i18n.t("features.1"),
        shortcut:" (alt + k)"
    }]
  return (
      <Box className="features">
        {
            features.map((feature,featureIndex)=>(
                <Feature key={featureIndex} details={feature} />
            ))
        }
      </Box>

  );
}

export default Features;