const ar={
    translation: {
        header: {
            title: "هل تحب الكتابة باللغة العربية؟",
            description: "أرابلكس هي لوحة مفاتيح افتراضية تسمح للأشخاص بالكتابة باللغة العربية على أي موقع ويب ، وتحول الحروف الهجائية اللاتينية إلى العربية.",
            actionCall: "تحميل"
        },
        features: [
            "يسمح لك الوضع العادي بالكتابة بالحروف الهجائية اللاتينية ( لغتك العادية ) أو مزج الأبجدية العربية مع الأبجدية اللاتينية.",
            "وضع لوحة المفاتيح يسمح لك لكتابة الحروف الهجائية العربية في أي موقع.",
            "يسمح لك وضع التحويل التلقائي بتحويل الحروف الهجائية اللاتينية إلى أبجديات عربية في الوقت الفعلي.",
            // "يقلل وضع الإكمال التلقائي من وقت الكتابة عن طريق اقتراح الكلمات ذات الصلة بناء على الأحرف الأولى المكتوبة.",
            "يشير الرمز البرتقالي إلى أنه لا يمكنك استخدام لوحة المفاتيح الافتراضية مؤقتا في بعض مواقع الويب.",
        ],
        quote: {
            title: "يمكن للجميع الكتابة باللغة العربية بثقة",
            description: "تجعل أرابلكس من السهل على أي شخص الكتابة باللغة العربية في أي مكان دون الحاجة إلى التبديل بين اللغات أو زيارة مواقع الويب الأخرى للتحويل أو الترجمة من اللغات اللاتينية."
        },
        footer: {
            copyright: "جميع الحقوق محفوظة.",
        }
    }
}

export default ar