import icon from "../assets/images/icon.png"
import "../assets/style/nav.css";

function Nav() {
    return <nav className="nav">
    <img className="navIcon" 
    src={icon}
    srcSet={``}
    alt={`Arablex Icon`}
    loading="lazy"
  /></nav>
}

export default Nav;