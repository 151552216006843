import { Avatar, Stack } from "@mui/material"
import { LOCALES } from "../i18n/locales"
import { useContext, useState } from "react"
import LocaleContext from "../contexts/LocaleContext"
import { LocaleActions } from "../reducers/LocaleReducer"
import GA4 from "../analytics/GA4"

function LanguagePicker  ()  {
    const localeContext=useContext(LocaleContext)
    const [isLanguageOptionVisible, setLanguageOptionVisible] = useState(false)

    const toggleLanguageOptions = (toggleEvent) => {
        setLanguageOptionVisible(!isLanguageOptionVisible)
    }
    const onLanguageOptionClick = (clickEvent) => {
        localeContext.dispatch({ "type": LocaleActions.SET, "lang": clickEvent.target.textContent })
        setLanguageOptionVisible(false);
        GA4.event({
            category: "language",
            action: "click",
            label: localeContext.locale, // optional
            /*nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image*/
        });

    }
    return (
        <Stack className={`languages`} >
            <Avatar className="language" onClick={toggleLanguageOptions}>
                {localeContext.locale}
            </Avatar>
            {
                isLanguageOptionVisible &&
                <Stack className={`language_options ${isLanguageOptionVisible && "options_visible"}`} >
                    {Object.values(LOCALES).filter(locale => locale.language !== localeContext.locale).map((locale, localeIndex) => (
                        <Avatar key={localeIndex} className="language"  onClick={onLanguageOptionClick}>
                            {locale.language}
                        </Avatar>
                    )
                    )}
                </Stack>
            }

        </Stack>)
}

export default LanguagePicker;