const fr={
    translation: {
        header: {
            title: "Vous aimez taper en arabe?",
            description: "Arablex est un clavier virtuel qui permet aux gens d'écrire en arabe sur n'importe quel site Web, et il transforme les alphabets latins en arabe.",
            actionCall: "télécharger"
        },
        features: [
            "le mode normal vous permet d'écrire en alphabets latins ( votre langue habituelle ) ou de mélanger l'alphabet arabe avec l'alphabet latin.",
            "Le mode clavier vous permet de taper des alphabets arabes sur n'importe quel site Web.",
            "le mode Transformer vous permet de transformer les alphabets latins en alphabets arabes en temps réel.",
            // "Le mode de saisie semi-automatique réduit votre temps de frappe en suggérant des mots pertinents en fonction des 1ères lettres tapées.",
            "L'icône orange indique que vous ne pouvez pas utiliser le clavier virtuel temporairement sur certains sites Web.",
        ],
        quote: {
            title: "Tout le monde Peut Écrire en arabe en toute Confiance",
            description: "Arablex permet à quiconque d'écrire facilement en arabe n'importe où sans avoir besoin de basculer entre les langues ou de visiter d'autres sites Web pour transformer ou traduire à partir de langues latines."
        },
        footer: {
            copyright: "Tous Droits Réservés.",
        }
    }
}

export default fr