import i18next from 'i18next';
import locales from './locales';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  fallbackLng: document.documentElement.lang, // if you're using a language detector, do not define the lng option
  debug: false,
  resources: locales,
}

i18next.use(LanguageDetector).init(options);
// initialized and ready to go!
// i18next is already initialized, because the translation resources where passed via init function


export default i18next