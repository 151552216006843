import { Box, Link, Typography } from "@mui/material";
import "../assets/style/header.css";
import videoSrc from "../assets/videos/kodknight-arablex.mp4"
import playIcon from "../assets/images/play.svg"
import pauseIcon from "../assets/images/pause.svg"
// import arablexIcon from "../assets/images/icon.png"

import { useContext, useEffect, useRef, useState } from "react";
import LocaleContext from "../contexts/LocaleContext";
import GA4 from "../analytics/GA4";
import FBPixel from "../analytics/FBPixel";
const storeLink = "https://chrome.google.com/webstore/detail/arablex/hmiaoaajpeeihpomninnakgicnhnnlab"
function Header() {
  const videoRef=useRef()
  const [isPlaying,setPlaying]=useState()

  const localeContext = useContext(LocaleContext)
const onLinkClick=(clickEvent)=>{
  FBPixel.track("ViewContent",{content_category:"page",contentType:"product",search_string:"actionCall"})

  GA4.event({
    category: "actionCall",
    action: "click",
    label: "download", // optional
    /*nonInteraction: true, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image*/
});
}
function OnclickVideo(videoClick){
  setPlaying(!isPlaying)
  isPlaying?
  videoRef.current.pause():
  videoRef.current.play()
}
useEffect(()=>{
  setPlaying(document.querySelector(".video video").paused)
},[videoRef])
  return (
    <Box className="header">
      <Box className="info">
        <Box className="video">
          <video loop={true} ref={videoRef} autoPlay={true} muted={true} onClick={OnclickVideo}>
            <source src={videoSrc} type="video/mp4"/>
          </video>
             <img className={`controls${isPlaying?" opaque":""}`} onClick={OnclickVideo} 
              src={!isPlaying?playIcon:pauseIcon}
              alt="controls"/>
        </Box>
        <Box className="description">
          <Typography variant="h6">{localeContext.i18n.t("header.title")}</Typography>
          <Typography variant="body1">{localeContext.i18n.t("header.description")}</Typography>
        </Box>
      </Box>
      <Link onClick={onLinkClick} href={storeLink} target="_blank">{localeContext.i18n.t("header.actionCall")}</Link>
    </Box>
  );
}

export default Header;
