import { StyledEngineProvider } from "@mui/material";
import React, { useEffect, useReducer } from "react";
import i18n from "./i18n/";
import LocaleContext from "./contexts/LocaleContext";
import LocaleReducer, { LocaleInit } from "./reducers/LocaleReducer";
import Header from "./components/Header";
import Features from "./components/Features";
import Quote from "./components/Quote";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import "./assets/style/global.css";
import FBPixel from "./analytics/FBPixel";

function App() {
  const [locale,dispatch]= useReducer(LocaleReducer,LocaleInit)
  useEffect(()=>{
    document.documentElement.lang=i18n.language
    FBPixel.pageView(); 
  },[])
  useEffect(()=>{
    document.documentElement.lang=i18n.language
  },[locale])
  return (
    <React.StrictMode>
      <LocaleContext.Provider value={{i18n,locale,dispatch}}>
        <StyledEngineProvider injectFirst={true}>
        <Nav />
        <Header />
        <Features />
        <Quote />
        <Footer />
          {/* {children} */}
        </StyledEngineProvider>
      </LocaleContext.Provider>
    </React.StrictMode>

  );
}

export default App;
