import { Box, Link, Typography } from "@mui/material";
import icon from "../assets/images/icon-white.png"

import LanguagePicker from "./LanguagePicker.js";
import { useContext } from "react";
import LocaleContext from "../contexts/LocaleContext";
import "../assets/style/footer.css";
import GA4 from "../analytics/GA4";

function Footer() {
  const localeContext = useContext(LocaleContext)
  const onLinkClick = (clickEvent) => {
    GA4.event({
      category: "actionCall",
      action: "click",
      label: "kodknight", // optional
      /*nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image*/
    });
  }
  return (
    <Box className="footer">
      <Link href="#"><img className="navIcon"
        src={icon}
        alt="Arablex Icon"
        loading="lazy"
      /></Link>
      <Typography variant="body2"><Link onClick={onLinkClick} href="https://kodknight.com" target="_blank">KodKnight </Link> &#169; {new Date().getFullYear()}, {localeContext.i18n.t("footer.copyright")}</Typography>
      <LanguagePicker />
    </Box>
  );
}

export default Footer;
