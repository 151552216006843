const en = {
    translation: {
        header: {
            title: "Love Typing in Arabic?",
            description: "Arablex is a virtual keyboard that allows people to write in Arabic on any website, and it transforms Latin alphabets into Arabic.",
            actionCall: "download"
        },
        features: [
            "the regular mode allows you to write in Latin alphabets ( your regular language ) or to mix the Arabic alphabet with the Latin alphabet.",
            "Keyboard mode allows you to type Arabic alphabets on any Website.",
            "Auto Transform mode allows you to transform Latin alphabets into Arabic alphabets in real-time.",
            // "Auto Complete mode reduces your typing time by suggesting relevant words based on the 1st letters typed.",
            "The orange icon indicates that you cannot use the virtual keyboard temporarily on some websites.",
        ],
        quote: {
            title: "Everyone Can Write in arabic with Confidence",
            description: "Arablex make it easy to anyone to write in Arabic anywhere without the need to switch between languages or visiting other websites to transform or translate from Latin languages."
        },
        footer: {
            copyright: "All Rights Reserved.",
        }
    }

}

export default en