import ar from "./AR"
import fr from "./FR"
import en from "./EN"

const locales ={ar,fr,en}

const LOCALES ={
    ENGLISH:new Intl.Locale('en-US'),
    ARABIC:new Intl.Locale('ar-arabext'),
    FRENCH:new Intl.Locale('fr-FR')
}
export {LOCALES,locales as default }