import i18next from "i18next"

export const LocaleInit = i18next.resolvedLanguage
export const LocaleActions = { "GET": "get", "SET": "set", }
function LocaleReducer(locale, action) {
    if (action.type === LocaleActions.SET) {
        i18next.changeLanguage(action.lang)
        document.documentElement.lang=i18next.language
        return action.lang
    }
    return locale
}

export default LocaleReducer;