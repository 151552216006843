import { Box, Typography } from "@mui/material";

function Feature({details}) {

  return (
    <Box className="feature">
      <Box className="image">
        <img
            src={details.imageURL}
            alt={`feature`}
          />
      </Box>

        <Typography variant="body1">{details.description} {details.shortcut&&details.shortcut}</Typography>
    </Box>
  );
}

export default Feature;
